import React, { Component } from "react";
import "./style.scss";

export default class Header extends Component {
  render(): React.ReactNode {
    return (
      <div id={"header"}>
        <div id={"logo"} />
      </div>
    )
  }
}